export const columnsConfigurationConstants = {
  COLUMNS_CONFIGURATION_LIST_REQUEST: 'COLUMNS_CONFIGURATION_LIST_REQUEST',
  COLUMNS_CONFIGURATION_LIST_SUCCESS: 'COLUMNS_CONFIGURATION_LIST_SUCCESS',
  COLUMNS_CONFIGURATION_LIST_FAILURE: 'COLUMNS_CONFIGURATION_LIST_FAILURE',
  COLUMNS_CONFIGURATION_LIST_CHANGED: 'COLUMNS_CONFIGURATION_LIST_CHANGED',
  COLUMNS_CONFIGURATION_LIST_PAGINATION: 'COLUMNS_CONFIGURATION_LIST_PAGINATION',

  COLUMNS_CONFIGURATION_LOAD_REQUEST: 'COLUMNS_CONFIGURATION_LOAD_REQUEST',
  COLUMNS_CONFIGURATION_LOAD_SUCCESS: 'COLUMNS_CONFIGURATION_LOAD_SUCCESS',
  COLUMNS_CONFIGURATION_LOAD_FAILURE: 'COLUMNS_CONFIGURATION_LOAD_FAILURE',

  COLUMNS_CONFIGURATION_SAVE_REQUEST: 'COLUMNS_CONFIGURATION_SAVE_REQUEST',
  COLUMNS_CONFIGURATION_SAVE_SUCCESS: 'COLUMNS_CONFIGURATION_SAVE_SUCCESS',
  COLUMNS_CONFIGURATION_SAVE_FAILURE: 'COLUMNS_CONFIGURATION_SAVE_FAILURE',
  COLUMNS_CONFIGURATION_DONE_SUCCESS: 'COLUMNS_CONFIGURATION_DONE_SUCCESS'
};
