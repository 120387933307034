export const dashboardConstants = {
  DASHBOARD_LIST_REQUEST: 'DASHBOARD_LIST_REQUEST',
  DASHBOARD_LIST_SUCCESS: 'DASHBOARD_LIST_SUCCESS',
  DASHBOARD_LIST_FAILURE: 'DASHBOARD_LIST_FAILURE',
  DASHBOARD_LIST_CHANGED: 'DASHBOARD_LIST_CHANGED',
  DASHBOARD_LIST_PAGINATION: 'DASHBOARD_LIST_PAGINATION',

  DASHBOARD_LOAD_REQUEST: 'DASHBOARD_LOAD_REQUEST',
  DASHBOARD_LOAD_SUCCESS: 'DASHBOARD_LOAD_SUCCESS',
  DASHBOARD_LOAD_FAILURE: 'DASHBOARD_LOAD_FAILURE',

  DASHBOARD_SAVE_REQUEST: 'DASHBOARD_SAVE_REQUEST',
  DASHBOARD_SAVE_SUCCESS: 'DASHBOARD_SAVE_SUCCESS',
  DASHBOARD_SAVE_FAILURE: 'DASHBOARD_SAVE_FAILURE',
  DASHBOARD_DONE_SUCCESS: 'DASHBOARD_DONE_SUCCESS'
};
