export const slotdataConstants = {
  SLOTDATA_LIST_REQUEST: 'SLOTDATA_LIST_REQUEST',
  SLOTDATA_LIST_SUCCESS: 'SLOTDATA_LIST_SUCCESS',
  SLOTDATA_LIST_FAILURE: 'SLOTDATA_LIST_FAILURE',
  SLOTDATA_LIST_CHANGED: 'SLOTDATA_LIST_CHANGED',
  SLOTDATA_LIST_PAGINATION: 'SLOTDATA_LIST_PAGINATION',

  SLOTDATA_LOAD_REQUEST: 'SLOTDATA_LOAD_REQUEST',
  SLOTDATA_LOAD_SUCCESS: 'SLOTDATA_LOAD_SUCCESS',
  SLOTDATA_LOAD_FAILURE: 'SLOTDATA_LOAD_FAILURE',

  SLOTDATA_SAVE_REQUEST: 'SLOTDATA_SAVE_REQUEST',
  SLOTDATA_SAVE_SUCCESS: 'SLOTDATA_SAVE_SUCCESS',
  SLOTDATA_SAVE_FAILURE: 'SLOTDATA_SAVE_FAILURE',
};
