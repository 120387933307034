export const dataexportConstants = {
  DATAEXPORT_LIST_REQUEST: 'DATAEXPORT_LIST_REQUEST',
  DATAEXPORT_LIST_SUCCESS: 'DATAEXPORT_LIST_SUCCESS',
  DATAEXPORT_LIST_FAILURE: 'DATAEXPORT_LIST_FAILURE',
  DATAEXPORT_LIST_CHANGED: 'DATAEXPORT_LIST_CHANGED',
  DATAEXPORT_LIST_PAGINATION: 'DATAEXPORT_LIST_PAGINATION',

  DATAEXPORT_SAVE_REQUEST: 'DATAEXPORT_SAVE_REQUEST',
  DATAEXPORT_SAVE_SUCCESS: 'DATAEXPORT_SAVE_SUCCESS',
  DATAEXPORT_SAVE_FAILURE: 'DATAEXPORT_SAVE_FAILURE',
};
