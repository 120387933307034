export const checkpointConstants = {
  CHECKPOINT_LIST_REQUEST: 'CHECKPOINT_LIST_REQUEST',
  CHECKPOINT_LIST_SUCCESS: 'CHECKPOINT_LIST_SUCCESS',
  CHECKPOINT_LIST_FAILURE: 'CHECKPOINT_LIST_FAILURE',
  CHECKPOINT_LIST_CHANGED: 'CHECKPOINT_LIST_CHANGED',
  CHECKPOINT_LIST_PAGINATION: 'CHECKPOINT_LIST_PAGINATION',

  CHECKPOINT_LOAD_REQUEST: 'CHECKPOINT_LOAD_REQUEST',
  CHECKPOINT_LOAD_SUCCESS: 'CHECKPOINT_LOAD_SUCCESS',
  CHECKPOINT_LOAD_FAILURE: 'CHECKPOINT_LOAD_FAILURE',

  CHECKPOINT_SAVE_REQUEST: 'CHECKPOINT_SAVE_REQUEST',
  CHECKPOINT_SAVE_SUCCESS: 'CHECKPOINT_SAVE_SUCCESS',
  CHECKPOINT_SAVE_FAILURE: 'CHECKPOINT_SAVE_FAILURE',
};
