import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";

import { userActions } from './_redux/authActions';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { loggedIn } = this.props;
    return loggedIn ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

function mapState(state) {
  const { loggedIn } = state.auth;
  return { loggedIn };
}

const actionCreators = {
  logout: userActions.logout,
}

export default connect(
  mapState,
  actionCreators
)(Logout);
