export const locationConstants = {
  LOCATION_LIST_REQUEST: 'LOCATION_LIST_REQUEST',
  LOCATION_LIST_SUCCESS: 'LOCATION_LIST_SUCCESS',
  LOCATION_LIST_FAILURE: 'LOCATION_LIST_FAILURE',
  LOCATION_LIST_CHANGED: 'LOCATION_LIST_CHANGED',
  LOCATION_LIST_PAGINATION: 'LOCATION_LIST_PAGINATION',

  LOCATION_LOAD_REQUEST: 'LOCATION_LOAD_REQUEST',
  LOCATION_LOAD_SUCCESS: 'LOCATION_LOAD_SUCCESS',
  LOCATION_LOAD_FAILURE: 'LOCATION_LOAD_FAILURE',

  LOCATION_SAVE_REQUEST: 'LOCATION_SAVE_REQUEST',
  LOCATION_SAVE_SUCCESS: 'LOCATION_SAVE_SUCCESS',
  LOCATION_SAVE_FAILURE: 'LOCATION_SAVE_FAILURE',
};
