let hostName = process.env.REACT_APP_CORE_API_BASE_URL;

if (typeof hostName === "undefined") {
  hostName = "";
}

let wsHost;
// wsHost = "http://dc.local:8679";

if (!wsHost) {
  wsHost = window.location.protocol + "//" + window.location.hostname;
  if (window.location.port !== 80 && window.location.port !== 443) {
    wsHost += ":" + window.location.port;
  }
}

console.log("wsHost", wsHost);

const aclHostName = hostName + "/api";
//const aclHostName = "http://127.0.0.1:20666";

export const endpoints = {
  // clickhouse service
  counter: {
    list: hostName + '/api/rawdata/v1/counters',
    save: hostName + '/api/rawdata/v1/edit',
  },

  event: {
    list: hostName + '/api/rawdata/v1/events',
    save: hostName + '/api/rawdata/v1/edit',
  },

  messages: {
    list: hostName + '/api/rawdata/v1/events',
    save: hostName + '/api/rawdata/v1/edit',
  },

  events: {
    list: hostName + '/api/rawdata/v1/events-custom',
  },

  messageMeters: {
    list: hostName + '/api/rawdata/v1/meters',
  },

  slotdata: {
    list: 'http://localhost:8666/api',
    save: 'http://localhost:8666/api',
  },

  // configuration service
  slot_config: {
    load: hostName + '/api/configuration/v1/slot-config',
    save: hostName + '/api/configuration/v1/slot-config',
    communication_protocol_types: hostName + "/api/configuration/v1/get-communication-protocol-types",
  },

  // slot sas configuration service
  slot_sas_config: {
    load: hostName + '/api/configuration/v1/slot-sas-config/:id/type/:type',
    save: hostName + '/api/configuration/v1/slot-sas-config',
  },

  location_config: {
    save: hostName + '/api/configuration/v1/location-config',
  },

  smib_config: {
    save: hostName + '/api/configuration/v1/smib-config',
  },

  tenant_config: {
    save: hostName + '/api/configuration/v1/tenant-config',
  },

  location: {
    list: hostName + '/api/configuration/v1/locations',
    save: hostName + '/api/configuration/v1/location',
    realtime: hostName + '/api/smib-realtime/v1/location/status/:idsList',
    archive: hostName + '/api/configuration/v1/archive/location',
    unarchive: hostName + '/api/configuration/v1/unarchive/location',
  },

  slot: {
    list: hostName + '/api/configuration/v1/slots',
    save: hostName + '/api/configuration/v1/slot',
    realtime: hostName + '/api/smib-realtime/v1/slot/status/:idsList',
    list_machine_meters: hostName + '/api/configuration/v1/slot-machine-meters/:slotId',
    load_machine_meter: hostName + "/api/configuration/v1/slot-machine-meter/:id",
    save_machine_meter: hostName + "/api/configuration/v1/slot-machine-meter",
    load_machine_meter_defs: hostName + "/api/settings/v1/mechanical-meters",
    archive: hostName + '/api/configuration/v1/archive/slot',
    unarchive: hostName + '/api/configuration/v1/unarchive/slot',
    set_machine_meters: hostName + "/api/configuration/v1/slot-machine-meters/:slotId",
    unassign_rule: hostName + "/api/configuration/v1/slot/:slotId/unassign-rule",
  },

  entity_group: {
    list: hostName + '/api/configuration/v1/:entityType/list-entity-groups',
    save: hostName + '/api/configuration/v1/:entityType/add-entity-group',
    get: hostName + '/api/configuration/v1/:entityType/get-entity-group',
    update: hostName + '/api/configuration/v1/:entityType/update-entity-group',
    delete: hostName + '/api/configuration/v1/:entityType/delete-entity-group',
  },

  smib: {
    list: hostName + '/api/configuration/v1/smibs',
    save: hostName + '/api/configuration/v1/smib',
    replace: hostName + '/api/configuration/v1/smib-to-smib',
    test_mode: hostName + '/api/configuration/v1/smib/:id/toggle-test',
    log_mode: hostName + '/api/configuration/v1/smib/:id/toggle-log',
    realtime: hostName + '/api/smib-realtime/v1/smib/status',
    refreshPass: hostName + '/api/configuration/v1/smib-refresh-password/:id',
    runSmibCmd: hostName + '/api/configuration/v1/smib-send-cmd/:id/type/:type',
    archive: hostName + '/api/configuration/v1/archive/smib',
    unarchive: hostName + '/api/configuration/v1/unarchive/smib',
    unassign: hostName + '/api/configuration/v1/smib/:id/unassign',
  },

  tenant: {
    list: hostName + '/api/configuration/v1/tenants',
    save: hostName + '/api/configuration/v1/tenant',
    realtime: hostName + '/api/smib-realtime/v1/tenant/status/:idsList',
    archive: hostName + '/api/configuration/v1/archive/tenant',
    unarchive: hostName + '/api/configuration/v1/unarchive/tenant',
    fraud_detector: hostName + "/api/fraud-detector-db/v1/tenant-settings/:id",
  },

  paymentTransaction: {
    list: hostName + '/api/payment-transactions-processor/v1/get-payment-transactions',
    load: hostName + '/api/payment-transactions-processor/v1/get-payment-transaction-log',
  },

  collectorCheckpoints: {
    list: hostName + '/api/fiscal-reports/v1/list-collector-checkpoints',
    load: hostName + '/api/fiscal-reports/v1/get-collector-checkpoint',
    save: hostName + '/api/fiscal-reports/v1/create-collector-checkpoint',
    get_actual_cash: hostName + '/api/fiscal-reports/v1/get-actual-cash-for-slot',
  },

  player_exclusion: {
    players: {
      list: hostName + '/api/player-exclusion-processor/v1/list-players',
      load: hostName + '/api/player-exclusion-processor/v1/get-player',
      update: hostName + '/api/player-exclusion-processor/v1/update-player',
      delete: hostName + '/api/player-exclusion-processor/v1/delete-player',
    },
    selfExclusions: {
      list: hostName + '/api/player-exclusion-processor/v1/list-self-exclusions',
      load: hostName + '/api/player-exclusion-processor/v1/get-self-exclusion',
      update: hostName + '/api/player-exclusion-processor/v1/update-self-exclusion',
      cancel: hostName + '/api/player-exclusion-processor/v1/cancel-self-exclusion',
      import_client_player_and_add_self_exclusion: hostName + '/api/player-exclusion-processor/v1/import-client-player-and-add-self-exclusion',
      import_onjn_self_exclusion: hostName + '/api/player-exclusion-processor/v1/import-onjn-self-exclusion',
      delete_onjn_self_exclusion: hostName + '/api/player-exclusion-processor/v1/delete-onjn-self-exclusion',
      // delete: hostName + '/api/player-exclusion-processor/v1/delete-self-exclusion',
    },
    playerScans: {
      list: hostName + '/api/player-exclusion-processor/v1/list-player-scans',
      load: hostName + '/api/player-exclusion-processor/v1/get-player-scan',
      update: hostName + '/api/player-exclusion-processor/v1/update-player-scan',
      // delete: hostName + '/api/player-exclusion-processor/v1/delete-player-scan',
    },
    blacklists: {
      list: hostName + '/api/player-exclusion-processor/v1/list-blacklists',
      load: hostName + '/api/player-exclusion-processor/v1/get-blacklist',
      update: hostName + '/api/player-exclusion-processor/v1/update-blacklist',
      cancel: hostName + '/api/player-exclusion-processor/v1/cancel-blacklist',
      // delete: hostName + '/api/player-exclusion-processor/v1/delete-blacklist',
    }
  },

  // auth service
  user: {
    login: aclHostName + '/authorization/v1/login',
    logout: aclHostName + '/authorization/v1/logout',
    check: aclHostName + '/authorization/v1/check',
    refresh: aclHostName + '/authorization/v1/refresh',
  },

  timelog: {
    list: hostName + '/api/timelog/v1',
  },

  aggregation: {
    list: hostName + '/api/aggregate/v1',
    list_with_intervals: hostName + '/api/aggregate/v1/:entityType/intervals',
    total_with_intervals: hostName + '/api/aggregate/v1/total/intervals',
    total: hostName + '/api/aggregate/v1/total',
  },

  checkpoint: {
    list: hostName + '/api/checkpoints/v1',
    save: hostName + '/api/checkpoints/v1',
  },

  task: {
    list: hostName + '/api/tasks/v1/completed',
    listcurrent: hostName + '/api/tasks/v1/current',
  },

  dataexport: {
    list: hostName + '/api/dataexport/v1/list',
    save: hostName + '/api/rawdata/v1/export',
  },

  jackpot: {
    list: hostName + '/api/jackpot-manager/v1/jackpots',
    save: hostName + '/api/jackpot-manager/v1/jackpot',
    stop: hostName + '/api/jackpot-manager/v1/jackpot/cancel-win-display',
    reset: hostName + '/api/jackpot-manager/v1/jackpot/reset',
    regen: hostName + '/api/jackpot-manager/v1/jackpot/regen',
    delete: hostName + '/api/jackpot-manager/v1/jackpot/delete',
    swagger_specs: hostName + '/api/jackpot-manager/v1/swagger.json',
  },

  jackpot_history: {
    list: hostName + '/api/jackpot-manager/v1/jackpot-wins',
    load: hostName + '/api/jackpot-manager/v1/jackpot-win',
  },

  jackpot_source: {
    list: hostName + '/api/jackpot-manager/v1/jackpot-sources',
    save: hostName + '/api/jackpot-manager/v1/jackpot-source',
    swagger_specs: hostName + '/api/jackpot-manager/v1/swagger.json',
  },

  display_channel: {
    list: hostName + '/api/display-channels/v1/displays',
    save: hostName + '/api/display-channels/v1/display',
    refreshBt: hostName + '/api/display-channels/v1/displays/update',
  },

  dashboard_settings: {
    list: hostName + '/api/settings/v1/get-dashboard-settings',
    save: hostName + '/api/settings/v1/add-dashboard-settings',
    update: hostName + '/api/settings/v1/update-dashboard-settings',
  },

  rawaggregation: {
    list: hostName + '/api/aggregations/v1/query',
    export: hostName + '/api/aggregations/v1/export',
  },

  log: {
    list: hostName + '/api/rawdata/v1/logs',
  },

  logError: {
    list: hostName + '/api/rawdata/v1/log-errors',
  },

  acl: {
    user: {
      list: aclHostName + '/users/v1/users',
      save: aclHostName + '/users/v1/user',
      delete: aclHostName + '/users/v1/user/delete',
    },
    entity_group: {
      list: aclHostName + '/users/v1/entity-groups',
      save: aclHostName + '/users/v1/entity-group',
      delete: aclHostName + '/users/v1/entity-group/delete',
    },
    permission: {
      list: aclHostName + '/users/v1/permissions',
      save: aclHostName + '/users/v1/permission',
      delete: aclHostName + '/users/v1/permission/delete',
    },
    user_group: {
      list: aclHostName + '/users/v1/user-groups',
      save: aclHostName + '/users/v1/user-group',
      delete: aclHostName + '/users/v1/user-group/delete',
    },
    role: {
      list: aclHostName + '/users/v1/roles',
      save: aclHostName + '/users/v1/role',
      delete: aclHostName + '/users/v1/role/delete',
    },
    rule: {
      list: aclHostName + '/users/v1/acl-rules',
      load: aclHostName + '/users/v1/acl-rule',
      save: aclHostName + '/authorization/v1/acl-rule',
      delete: aclHostName + '/authorization/v1/acl-rule/delete',
    },
    api_token: {
      list: aclHostName + '/users/v1/api-tokens',
      save: aclHostName + '/authorization/v1/api-token',
      load: aclHostName + '/users/v1/api-token',
      delete: aclHostName + '/users/v1/api-token/delete',
    },
    models: {
      load: aclHostName + "/authorization/v1/acl-model-fields",
    }
  },

  admin: {
    aggregation_rule: {
      list: hostName + '/api/configuration/v1/aggregation-rules',
      save: hostName + '/api/configuration/v1/aggregation-rule',
    },
    columns_configuration: {
      list: hostName + '/api/settings/v1/get-system-all-columns',
      get: hostName + '/api/settings/v1/get-page-columns',
      get_by_page_route: hostName + '/api/settings/v1/get-system-page-columns-by-page-route/:page',
      save: hostName + '/api/settings/v1/add-page-columns',
      update: hostName + '/api/settings/v1/update-page-columns',
      delete: hostName + '/api/settings/v1/delete-page-columns',
      dataTypes: hostName + '/api/settings/v1/data-types'
    },

    general_settings: {
      list: hostName + '/api/settings/v1/get-general-settings/type/:type/:idsList',
      save: hostName + '/api/settings/v1/add-general-settings',
      update: hostName + '/api/settings/v1/update-general-settings',
      swagger_specs: hostName + '/api/settings/v1/swagger.json',
    },
  },

  daily_monthly_legal: {
    list: hostName + '/api/fiscal-reports/v1/list/:location_id',
    get_validated_report: hostName + '/api/fiscal-reports/v1/validated-report',
    validate_report: hostName + '/api/fiscal-reports/v1/validate',
    // TODO - switch back after work is done on Monthly Jackpot Reports
    jackpot_checkpoints: hostName + '/api/jackpot-snapshot/v1/jackpot-checkpoints',
    //jackpot_checkpoints: 'https://zeus-test2.slotmonitor.ro/api/jackpot-snapshot/v1/jackpot-checkpoints',
    list_monthly_reports: hostName + '/api/fiscal-reports/v1/list-monthly-reports/:location_id',
    get_monthly_report_with_validated_daily_reports: hostName + '/api/fiscal-reports/v1/monthly-report-with-validated-daily-reports',
    get_monthly_report_all_daily_reports: hostName + '/api/fiscal-reports/v1/monthly-report-all-daily-reports',
    get_monthly_report_slot_ranges_intervals: hostName + '/api/fiscal-reports/v1/monthly-report-slot-ranges-intervals',
  },

  okto_processor: {
    get_merchant_data: hostName + '/api/okto-processor/v1/get-merchant-data',
    tenant_settings: hostName + '/api/okto-processor/v1/tenant-settings',
    swagger_specs: hostName + '/api/okto-processor/v1/swagger.json',
  },

  slotcontrol: {
    slot_status : hostName +'/api/slotcontrol/v1/slot-status',
    refresh_registration: hostName +'/api/slotcontrol/v1/aft/refresh-registration',
    set_asset_number: hostName +'/api/slotcontrol/v1/aft/set-asset-number',
    lock : hostName +'/api/slotcontrol/v1/lock',
    unlock : hostName +'/api/slotcontrol/v1/unlock',
  },

  remote_cashout: {
    list: hostName + "/api/remote-cashout/v1/list",
    reset: hostName + "/api/remote-cashout/v1/reset",
  },

  websocket: {
    host: wsHost,
    path: "/api/notifications-ws/v1/connect"
  },

  notifications: {
    list: hostName + "/api/notifications/v1/notifications",
    seen: hostName + "/api/notifications/v1/notifications/seen",
    confirm: hostName + "/api/notifications/v1/notifications/confirm",
    templates: {
      load: hostName + "/api/notifications/v1/notification-templates",
    },
    subscriptions: {
      load: hostName + "/api/notifications/v1/user-subscriptions",
      save: hostName + "/api/notifications/v1/user-subscriptions/set",
    }
  },
  games_registry: {
    machine_types: {
      list: hostName + '/api/games-registry/v1/list-machine-types',
      load: hostName + '/api/games-registry/v1/get-machine-type',
      save: hostName + '/api/games-registry/v1/add-machine-type',
      update: hostName + '/api/games-registry/v1/update-machine-type',
      delete: hostName + '/api/games-registry/v1/delete-machine-type',
    },
    games: {
      list: hostName + '/api/games-registry/v1/list-games',
      load: hostName + '/api/games-registry/v1/get-game',
      save: hostName + '/api/games-registry/v1/add-game',
      update: hostName + '/api/games-registry/v1/update-game',
      delete: hostName + '/api/games-registry/v1/delete-game',
    },
    game_mixes: {
      list: hostName + '/api/games-registry/v1/list-game-mixes',
      load: hostName + '/api/games-registry/v1/get-game-mix',
      save: hostName + '/api/games-registry/v1/add-game-mix',
      update: hostName + '/api/games-registry/v1/update-game-mix',
      delete: hostName + '/api/games-registry/v1/delete-game-mix',
    },
    game_profiles: {
      list: hostName + '/api/games-registry/v1/list-game-profiles',
      load: hostName + '/api/games-registry/v1/get-game-profile',
      save: hostName + '/api/games-registry/v1/add-game-profile',
      update: hostName + '/api/games-registry/v1/update-game-profile',
      delete: hostName + '/api/games-registry/v1/delete-game-profile',
    },
    manufacturers: {
      list: hostName + '/api/games-registry/v1/list-manufacturers',
      load: hostName + '/api/games-registry/v1/get-manufacturer',
      save: hostName + '/api/games-registry/v1/add-manufacturer',
      update: hostName + '/api/games-registry/v1/update-manufacturer',
      delete: hostName + '/api/games-registry/v1/delete-manufacturer',
    },
    protocols: {
      list: hostName + '/api/games-registry/v1/list-protocols',
      load: hostName + '/api/games-registry/v1/get-protocol',
      save: hostName + '/api/games-registry/v1/add-protocol',
      update: hostName + '/api/games-registry/v1/update-protocol',
      delete: hostName + '/api/games-registry/v1/delete-protocol',
    },
  },
};
