export const slotConstants = {
  SLOT_LIST_REQUEST: 'SLOT_LIST_REQUEST',
  SLOT_LIST_SUCCESS: 'SLOT_LIST_SUCCESS',
  SLOT_LIST_FAILURE: 'SLOT_LIST_FAILURE',
  SLOT_LIST_CHANGED: 'SLOT_LIST_CHANGED',
  SLOT_LIST_PAGINATION: 'SLOT_LIST_PAGINATION',

  SLOT_LOAD_REQUEST: 'SLOT_LOAD_REQUEST',
  SLOT_LOAD_SUCCESS: 'SLOT_LOAD_SUCCESS',
  SLOT_LOAD_FAILURE: 'SLOT_LOAD_FAILURE',

  SLOT_SAVE_REQUEST: 'SLOT_SAVE_REQUEST',
  SLOT_SAVE_SUCCESS: 'SLOT_SAVE_SUCCESS',
  SLOT_SAVE_FAILURE: 'SLOT_SAVE_FAILURE',

  //SLOT SAS CONFIG TYPES
  SLOT_SAS_CONFIG_DB: 1,
  SLOT_SAS_CONFIG_SMIB_SAVE: 2,
  SLOT_SAS_CONFIG_SMIB_NO_SAVE: 3,

};
